import React, { useState } from "react";
import Layout from "../components/layout";
import $ from "jquery";
import { Helmet } from "react-helmet";

export default function Contact() {
  const [message, setMessage] = useState({
    text: "",
    show: false,
  });

  const [form, setForm] = useState({
    name: "",
    email: "",
    telephone: 0,
    company: "",
    body: "",
  });

  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const sendForm = (e) => {
    e.preventDefault();

    const formData = form;
    setSending(true);

    $.ajax({
      url: "https://vdbrecherche.nl/contact/sendEmail.php",
      method: "POST",
      dataType: "json",
      data: {
        body: formData.body,
        name: formData.name,
        email: formData.email,
        telephone: formData.telephone,
        company: formData.company,
      },
      success: function (response) {
        if (response.status === "success") {
          setSending(false);
          setMessage({
            text: "Formulier is verstuurd!",
            show: true,
          });
          document.querySelector("form").reset();
        } else {
          setSending(false);
          setMessage({
            text: "Er ging iets mis!",
            show: true,
          });
        }
      },
      error: function (request, status, error) {
        setSending(false);
        setMessage({
          text: "Er ging iets mis!",
          show: true,
        });
      },
    });
  };

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>VDB Consulting</title>
        <meta name="format-detection" content="telephone=no"></meta>
      </Helmet>
      <div className="contactPage">
        <form onSubmit={sendForm} encType="multipart/form-data">
          {message.show ? (
            <p style={{ color: "white" }}>{message.text}</p>
          ) : null}
          <h2>
            Stuur een bericht en u ontvangt <span>binnen 24 uur</span> een
            reactie.
          </h2>
          <div className="sect">
            <input
              onChange={handleChange}
              id="name"
              name="name"
              type="text"
              placeholder="Naam"
            />
            <input
              onChange={handleChange}
              type="email"
              id="email"
              name="email"
              placeholder="E-mailadres"
            />
          </div>
          <div className="sect">
            <input
              onChange={handleChange}
              type="number"
              id="telephone"
              name="telephone"
              placeholder="Telefoon"
            />
            <input
              onChange={handleChange}
              type="text"
              id="company"
              name="company"
              placeholder="Organisatie"
            />
          </div>
          <textarea
            onChange={handleChange}
            id="body"
            name="body"
            placeholder="Uw bericht"
          />
          <button type="submit">Versturen</button>
        </form>
      </div>
    </Layout>
  );
}
